import GatsbyImage from "gatsby-image"
import styled from "styled-components"

export const ItemsWrapper = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1123px;
  width: 100%;
  margin: 0 auto;
  /* margin-bottom: 141px; */
  text-align: start;
  @media (max-width: 1144px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: 100px;
  }

  @media (max-width: 460px) {
    margin-bottom: 50px;
  }
`
export const Space = styled.div`
  height: 133px;
  @media (max-width: 460px) {
    height: 50px;
  }
`

export const StyledImage = styled(GatsbyImage)`
  width: 720px;
  height: 500px;
  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
  }
`

export const Blob = styled.div`
  background-image: ${props => `url(${props.url})`};
  background-repeat: no-repeat;
  /* width: ${props => props.width}px; */
  width: 100%;
  /* height: ${props => props.height}px; */
  height: 100%;
  @media (max-width: 641px) {
    transform: scale(0.8);
  }
  @media (max-width: 360px) {
    transform: scale(0.7);
  }
  @media (max-width: 280px) {
    transform: scale(0.9);
  }
`

export const Img = styled(GatsbyImage)`
  max-width: 420px;
  max-height: 420px;
  width: 100%;
  height: 100%;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
  position: relative;
  order: ${({ order }) => order};
  @media (max-width: 1144px) {
    order: 0;
  }
`
export const WrapperHero = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  text-align: start;
  background-color: #eeeceb;
  @media (max-width: 1269px) {
    flex-direction: column;
    text-align: center;
    background-color: transparent;
  }
`

export const H1 = styled.h1`
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.04em;
  color: #484848;
  margin-bottom: 32px;

  @media (max-width: 1366px) {
    font-size: 38px;
    line-height: 38px;
  }
  @media (max-width: 460px) {
    font-size: 38px;
    line-height: 38px;
  }
`

export const Image = styled.div`
  background-image: ${props => `url(${props.url})`};
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 720px;
  width: 100%;
  height: 470px;
`

export const LeftBox = styled.div`
  padding: 0px 60px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 720px;
  background-color: #eeeceb;
  order: 0;
  @media (max-width: 1269px) {
    order: 1;
    width: 100%;
    padding: 25px;
    height: 100%;
    align-items: center;
  }
`

export const P = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #484848;

  /* @media (max-width: 460px) {
    font-size: 16px;
  } */
`

export const Button = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.06em;
  color: #222222;
  padding: 16px 53px;
  border: 1px solid #484848;
  border-radius: 30px;
  display: inline-block;
  margin-top: 32px;
  cursor: pointer;
  white-space: nowrap;

  @media (max-width: 641px) {
    padding: 16px 23px;
  }
  &:hover {
    color: #fcfcfc;
    background-color: #222222;
    transition: all 0.1s;
  }
`

export const ItemsContent = styled.div`
  order: ${props => props.order};
  color: #222222;
  max-width: 548px;
  width: 100%;
  @media (max-width: 999px) {
    order: 0;
  }
  h2 {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.5px;
    margin-bottom: 24px;
    /* @media (max-width: 999px) {
      font-size: 18px;
    } */
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #484848;
    @media (max-width: 1144px) {
      margin-bottom: 20px;
    }
  }
`
